import React from "react"
import { graphql } from "gatsby"
import SEOArticle from "../components/SEO/article"
import SEOWebpage from "../components/SEO/webpage"
import Head from "../components/Head"
import Layout from "../components/Layout"
import ShareButtons from "../components/ShareButtons"
import ArticleFooter from "../components/ArticleFooter/index"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const BlogServicesTemplate = ({ data, pageContext, location }) => {
  const post = data.graphCmsServiceArea
  const services = data.allGraphCmsServicePosts.edges;

  const { slug } = pageContext
  const postUrl = data.site.siteMetadata.siteUrl + '/service-areas/' + slug + "/";
  const pageTitle = data.graphCmsServiceArea.locationName + ', ' + data.graphCmsServiceArea.stateLocation + ' Refrigeration and HVAC Services';

  return (
    <Layout>
      <Head
        title={pageTitle}
        // pageDesc={post.metaDescription}
        url={postUrl}
      />
      <SEOWebpage
        title={pageTitle}
        url={postUrl}
      />
      <article className="services-post" >
        <header>
          <h1>{pageTitle}</h1>
          <hr />
        </header>
        <div className="articleBody">
        <h2>Your commercial partner proudly serving { data.graphCmsServiceArea.locationName }, { data.graphCmsServiceArea.stateLocation }</h2>
        <p> Valley Commercial Service is proud to serve the businesses of { data.graphCmsServiceArea.locationName }, { data.graphCmsServiceArea.stateLocation } for all their HVAC, Refrigeration, Freezer and cooking equipment needs! </p>
        <p> With over 80 years of experiance our trained technicians will diagnose, repair and service your equipment for maximum efficiency and comfort.</p>
        <p> We’ve developed partnerships with several trusted manufacturers so you can count on the products we carry. </p>
        <h3>Services we offer in { data.graphCmsServiceArea.locationName }, { data.graphCmsServiceArea.stateLocation } </h3>
        <ul className="iconBlocks">
          {services.map((service, index) => 
            <li><a href={'/services/' + service.node.slug + '/'}>{service.node.title}</a></li>
          )}
        </ul>
        <h3>Do you need commercial equipment repairs in { data.graphCmsServiceArea.locationName }, { data.graphCmsServiceArea.stateLocation }?</h3>
        <p>

        Done Fast.
        And Done Right.
 
        That's what we do
        </p>
        </div>
      </article>
    </Layout>
  )
}

export default BlogServicesTemplate

export const pageQuery = graphql`
query LocationAreas($slug: String!) {
  site {
    siteMetadata {
      siteUrl
      title
    }
  }
  graphCmsServiceArea(slug: {eq: $slug}) {
    id
    slug
    locationName
    stateLocation
  }
  allGraphCmsServicePosts {
    edges {
      node {
        title
        slug
        metaDescription
      }
    }
  }
}
`
